/*****************************************
* Author: Arun Sengupta (arunace) *
*****************************************/

/**** CSS Reset ****/
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
img,
ins,
kbd,
q,
s,
samp,
strike,
strong,
dl,
dt,
dd,
ol,
ul,
li,
figure,
figcaption,
fieldset,
form,
label,
legend {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
}

:root {
    /*TODO WHITE LABELING COLOR CODE*/
    --primary: #231F20;
    --secondary: #ECECE1;
    --active: #000;
    --deal: #000;
    --white: #FFFFFF;
    --danger: #FC6A59;
    --success: #01B27C;
}

a,
a:hover {
    text-decoration: none !important;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

html,
body {
    font-family: "Fort Light", sans-serif;
    font-size: 14px;
}

.redirect {
    color: white;
    background-color: var(--deal);
}


.btn-dark {
    background-color: var(--deal) !important;
}

.btn-outline-danger:hover {
    background-color: #FC6A59 !important;
}

.btn-success {
    background-color: #01B27C !important;
}

.wrap {
    box-sizing: border-box;
    max-width: 1366px;
    padding: 0 80px;
    margin: 0px auto;
}

#header {
    background: var(--white);
    color: var(--primary);
    font-size: 1rem;
    font-weight: bold;
    max-height: 85px;
    padding: 30px 0;
}

.logo {
    max-width: 350px;
    max-height: 40px;
}

.login_logo {
    max-width: 350px;
    max-height: 40px;
}

.mob-logo {
    max-width: 61px;
    max-height: 28px;
}


#header nav ul {
    list-style-type: none;
}

#header nav ul li {
    display: inline-block;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #1e1f29;
    border-color: #1e1f29;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #1e1f29;
    text-decoration: none;
    background-color: var(--white);
    border: 1px solid #ddd;
}

#header nav ul li a {
    font-weight: bold;
    color: var(--primary);
}

#header nav ul li a:hover,
#header nav ul li a.active {
    color: #666;
}

.bell {
    position: relative;
}

.user img,
.bell img {
    max-height: 21px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.bell>span {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 22px;
    height: 22px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #242424;
    color: #fff;
    font-size: 0.625rem;
    font-weight: bold;
    text-align: center;
    padding-top: 2px;
}

.user:hover,
.bell:hover {
    opacity: .7;
}

/********/

.big-btn {
    padding: 15px 80px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.big-btn:hover {
    opacity: .8;
}

#view-pass {
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

#view-pass:hover {
    filter: brightness(30%);
}


/********/

.my-cart-list a:hover {
    opacity: .7;
}


.single-product {
    font-size: 1.222rem;
    font-weight: 400;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #EBEBEB !important;
    padding: 0;
}

.single-product:last-child {
    border-bottom: none;
}

.product-thumb {
    display: block;
    background-color: var(--white);
    max-width: 90px;
    max-height: 90px;
    text-align: center;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.card.shadow-dreamy {
    border-radius: 10px;
}

.body-content .scroll {
    height: 65vh;
    overflow: auto;
}

.my-cart-list .product-thumb {
    display: block;
    background-color: var(--white);
    text-align: center;
    align-items: center;
    height: 100px;
    width: 100px;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.product-thumb.small,
.my-cart-list .product-thumb.small {
    max-width: 60px;
    max-height: 60px;
}

.my-cart-list .product-thumb:hover img {
    transform: none;
}

.my-cart-list .product-thumb img {
    height: 85px;
    width: 100%;
    object-fit: contain;
}

.my-cart-list .single-product .product-name {
    font-size: 1.222rem;
    color: var(--primary);
}


.my-cart-list .price,
.my-cart-list .single-product .price {
    text-align: right;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary);
}

a.text-dark:hover {
    opacity: .7;
}

/********/

#footer {
    margin-top: 60px;
    padding-bottom: 40px;
}

.footer-logo {
    margin-top: 5px;
    max-height: 40px;
}
.proglint-footer-logo{
    height: 80px;
    margin-top: -22px;
}
.img_center_popup{
    object-fit: cover;
    width: 100%;
    height: 400px;
}
.placeholderImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

#footer nav ul {
    list-style-type: none;
}

#footer nav ul li {
    display: inline-block;
}

#footer nav ul a {
    color: var(--primary);
    font-size: 1rem;
    font-weight: bold;
}

#footer nav ul a:hover {
    color: #666;
}


/*******/

#account-nav {
    border-right: 1px solid #D4D4D4;
}

#account-nav ul {
    list-style-type: none;
}

#account-nav ul li {
    padding: 15px 0;
}

#account-nav ul li a {
    color: var(--primary);
    font-size: 1rem;
    font-weight: normal;
}

#account-nav ul li a:hover {
    opacity: .6;
}

#account-nav ul li a.active {
    font-weight: bold;
}

#account-nav ul li a img {
    width: 18px;
}


/***********/

.dotted-divider {
    border-top: 1px dashed #999;
    border-bottom: 1px dashed #999;
}


hr.thick {
    background-color: #dbdbdb;
    height: 4px;
}


/********/

.offcanvas-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -350px;
    width: 300px;
    min-height: 100%;
    max-height: 100%;
    background-color: var(--white);
    z-index: 999;
    padding: 40px 35px;
    overflow-y: auto;
    transition: .4s all;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    -o-transition: .4s all;
}

.offcanvas-menu.show {
    left: 0;
}

.offcanvas-menu nav a {
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #707070;
    font-size: 1em;
    font-weight: normal;
    color: var(--primary);
}

.offcanvas-menu nav a.active {
    font-weight: bold;
}

.offcanvas-menu nav a:last-child {
    border-bottom: 0;
}

.offcanvas-menu a.logout {
    color: #d50606;
}

.offcanvas-menu a:hover {
    opacity: .7;
}


.close-offcanvas {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--primary) !important;
    font-size: 1rem;
    z-index: 990;
    padding: 10px;
}

.close-offcanvas:hover {
    color: #999 !important;
}

.submenu-link {
    background-image: url("../img/menu-plus.svg");
    background-repeat: no-repeat;
    background-position: center right;
}

.submenu {
    display: none;
}

.submenu li {
    display: block !important;
}

.submenu li a {
    padding: 10px 0;
}

.submenu-link.active {
    background-image: url("../img/menu-minus.svg");
}

.modal-close {
    cursor: pointer;
    width: 38px;
    position: absolute;
    top: -20px;
    right: -20px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.modal-close:hover {
    filter: brightness(80%);
}



/**** Merchant APP ****/

.quantity {
    border: 1px solid #000;
    max-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
}

.quantity .form-control {
    border: 0;
}


#store-info img {
    width: 100px;
}

#store-open:checked,
#store-close:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

input[type='radio'] {
    margin-top: 6px;
    transform: scale(2);
}

#analytics .card {
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

/* #analytics .card:hover { filter: brightness(95%); } */


#order-options-bar .form-select,
#order-options-bar .form-control {
    display: inline-block !important;
    width: auto !important;
}

.datefield,
.ddownfield {
    max-width: 115px;
}


.single-order {
    color: var(--primary);
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.single-order:hover {
    color: var(--primary);
    opacity: .7;
}


/**********/

#full-notifications .tab-content {
    padding-top: 30px;
}


#full-notifications .nav-link {
    color: #666;
    border: 0;
}

#full-notifications .nav-link.active {
    font-weight: bold;
    border-bottom: 2px solid #000;
    color: var(--primary);
}

#product-details-modal .product-preview {
    max-height: 180px;
}

/**********/

#planogram>.row {
    padding-left: 4px;
}

/* adjusting for the margin-left -4 for each button below */

#planogram .btn {
    min-width: 56px;
    padding: 10px 14px;
    text-align: center;
    font-size: 1.286rem;
    font-weight: bold;
    margin-left: -4px;
    margin-bottom: 15px;

}

#planogram .btn.bigger {
    border-color: #ff77bc;
    width: auto;
    max-width: 54px;
    height: auto;
    padding: 7px;
    text-align: left;
    font-size: 9px;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: -4px;
}


.store-row {
    margin-bottom: 20px;
}

.ghost-block {
    width: 50px;
    height: 50px;
    background-color: var(--white);
    border-color: #fff;
}


.breadcrumb-item a {
    color: var(--primary);
    font-size: .857rem;
    font-weight: normal;
}

.breadcrumb-item.active {
    color: var(--primary);
    font-weight: bold;
}

.bot-btn {
    width: 100%;
}



/***************/

#chat-content {
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
}

#chatting-window .badge.bg-danger {
    display: inline-block;
    margin-top: -20px;
}

.chat-history {
    height: 475px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

#chatting-window .input-group img {
    width: 22px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

#chatting-window .input-group img:hover {
    opacity: .6;
}


.chat-history ul {
    list-style-type: none;
    font-size: 1rem;
}

.chat-history ul .sender {
    float: right;
    background-color: var(--primary);
    padding: 6px 10px;
    margin-bottom: 7px;
    color: #fff;
    width: auto;
    max-width: 80%;
    border-radius: 10px 10px 0 10px;
    clear: both;
}

.chat-history ul .receiver {
    float: left;
    background-color: #D6D3CC;
    padding: 6px 10px;
    margin-bottom: 7px;
    color: var(--primary);
    width: auto;
    max-width: 80%;
    border-radius: 10px 10px 10px 0;
    clear: both;
}

.chat-history ul .sender .time {
    display: block;
    font-size: 0.714rem;
    color: #b1b1b1;
    text-align: right;
}

.chat-history ul .receiver .time {
    display: block;
    font-size: 0.714rem;
    color: #606060;
    text-align: right;
}

#chat-tabs .nav-item .nav-link {
    border: 0;
    color: #666;
    padding: 5px;
    font-size: 1rem;
}

#chat-tabs .nav-item.show .nav-link,
#chat-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid #000;
    font-weight: bold;
    color: var(--primary);
}



/**** Overwrite ****/

.btn:focus,
.form-select,
.form-control,
.form-control:focus {
    border: 1px solid #000;
    border-radius: 0;
    box-shadow: none;
    outline: none;
}

.btn {
    border-radius: 0;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.modal a:hover {
    opacity: .7;
}


.rack_wise_custom{
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.form-che-dashbaord {
    padding-left: 1.2rem !important;
}

button.btn-dark:hover {
    opacity: .7;
}

.alert-danger {
    background-color: #FFEDED;
    color: var(--primary);
    border: 0;
}

.btn-primary {
    background-color: #4172CC;
    border-color: #4172CC;
}

.text-warning {
    color: #EF8F0C !important;
}

.pagination .active .page-link {
    border-color: #333;
    font-weight: bold;
    background-color: var(--white);
}


.pagination .page-item .page-link {
    color: #333;
    box-shadow: none;
}


.status-line {
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    z-index: 1;
}

.status-row {
    z-index: 2;
}

.status-row .col img {
    border: 3px solid #fff;
    border-radius: 50%;
    background-color: var(--white);
}



/*****************/
.bg-cream {
    background-color: var(--secondary);
}

.bg-ltgrey {
    background-color: #F8F8F8;
}

.bg-ltgrey-1 {
    background-color: #c7c7c7;
}

.bg-pgreen {
    background-color: #BEF7C7;
}

.bg-ppurple {
    background-color: #C4D0F8;
}

.bg-porange {
    background-color: #F7DAB8;
}


.txtgrey {
    color: #878787;
}



.py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.my-75 {
    margin-top: 75px;
    margin-bottom: 75px;
}


.maxwidth250 {
    max-width: 250px;
}

.p-50 {
    padding: 50px;
}

.p-40 {
    padding: 40px;
}


.font40 {
    font-size: 2.857rem;
}

.font28 {
    font-size: 2rem;
}

.font20 {
    font-size: 1.429rem;
}

.font18 {
    font-size: 1.286rem;
}

.font16 {
    font-size: 1.143rem;
}

.font15 {
    font-size: 1.071rem;
}

.font14 {
    font-size: 1rem;
}

.font13 {
    font-size: .929rem;
}

.font12 {
    font-size: .857rem;
}

.font11 {
    font-size: .786rem;
}

.font10 {
    font-size: 0.7rem;
}



/**** Media Queries ****/

@media screen and (max-width: 1024px) {

    .wrap {
        padding-left: 20px;
        padding-right: 20px;
    }

    .wrap.py-50 {
        padding-top: 0;
        padding-bottom: 0;
    }

    .product-thumb {
        padding: 0;
        height: 240px;
    }

    #header-shadow {
        border-bottom: 0 !important;
        box-shadow: none !important;
    }

    #header {
        padding: 20px 0;
        max-height: 130px;
    }


    #footer nav ul li {
        margin-bottom: 25px;
    }

    .modal-body.p-50 {
        padding: 25px;
    }

    .modal-close {
        top: -20px;
        right: -8px;
    }

    .bot-btn {
        width: auto;
    }
}

@media screen and (max-width: 768px) {

    .datefield,
    .ddownfield,
    #order-options-bar .datefield,
    #order-options-bar .ddownfield {
        width: 100% !important;
        max-width: 100% !important;
    }

    .modal-body.p-40 {
        padding: 20px;
    }
}

/* Planogram Started */
#planogram .btn-sm {
    min-width: 56px;
    padding: 3px 5px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    margin-left: -4px;
    margin-bottom: 15px;
}

.text-center {
    text-align: center;
}

.c-pointer {
    cursor: pointer;
}

.store-location {
    position: relative;
    height: 80vh;
    border: 1px solid #D4D4D4;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 26%);
}

.store-location-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 26%);
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.handle {
    position: absolute;
}

.close-icon {
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 10px;
    border: 1px solid red;
    background-color: red;
    width: 16px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    color: #FFFFFF;
}

.rotate-icon {
    position: absolute;
    top: -3px;
    left: -3px;
    font-size: 10px;
    border: 1px solid green;
    background-color: green;
    width: 16px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    color: #FFFFFF;
}

.planogram-button {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 0px;
}

.planogram .col-border {
    border: 1px solid #D4D4D4;
    width: 100%;
    /* overflow: hidden; */
    padding: 10px;
    padding-right: 20px;
    position: relative;

}

.planogram-card-container {
    width: 100%;
    overflow: hidden;
    height: 100%;
    padding-right: 30px;
    position: relative;
}

.planogram-card-content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

.planogram .bay-card {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 10px;
}

.planogram .card-Bay-stock-left {
    background-color: #13A72C;
    color: #fff;
    width: 30%;
    height: 6%;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.planogram .card-body {
    background: linear-gradient(to bottom, #5a5a5a 0%, #000000 100%);
    color: #fff;
}

.planogram .bay-id,
.planogram .bay-product {
    margin: 0;
}

.planogram .btn-bay-card {
    background-color: #fff;
    padding: 3px 10px 3px 10px;
    margin: 0;

    border-radius: 25px;
}

.planogram .h-ver {
    display: flex;
    align-items: center;
    transform: rotate(-90deg);
    vertical-align: middle;
}

.planogram .h-ver1 {
    visibility: hidden;
}

.planogram .height-align {
    transform: rotate(90deg);
    position: relative;
    right: 0px;
}

.planogram .text-vert {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4%;
}

.planogram .row-text {
    padding-top: 8px;
    padding-bottom: 8px;
}

.planogram .w-text {
    text-align: center;
    vertical-align: middle;
    margin-bottom: 0rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .planogram .btn-bay-card-left {
    position: absolute;
    top: 8px;
    left: 8px;
    border: 2px solid #000000;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 10px;
    font-weight: 700;
    color: #000000;
    padding: 0px 4px;
    border-radius: 50px;
} */
.planogram .btn-bay-card-left {
    justify-content: center;
    display: flex;
    font-size: 10px;
    font-weight: 700;
    color: #000000;
    padding: 0px 4px;
    border-radius: 50px;
    width: fit-content;
}

.planogram .btn-green {
    border: 1.5px solid #13A72C;
    color: #13A72C;
}

.planogram .btn-yellow {
    border: 1.5px solid #FFCC00;
    color: #FFCC00;
}

.planogram .btn-partial-stock {
    border: 1.5px solid #efad4d;
    color: #efad4d;
}

.planogram .btn-red {
    border: 1.5px solid #db2323;
    color: #db2323;
}

.planogram-view-button svg {
    margin-right: 10px;
}

.card-img-section {
    position: relative;
    display: flex;
    justify-content: center;
}

.btn-bay-product-edit {
    position: absolute;
    top: 8px;
    right: 8px;
}

.card-img-section img {
    width: auto;
    height: 120px;
}

.planogram-hr {
    width: 150px;
    background-color: #000;
    height: 2px;
}

.right-hr {
    margin: 0px 0px 0px 15px;
}

.left-hr {
    margin: 0px 15px 0px 0px;
}

.planogram-hr-top-bottom {
    width: 60px;
    background-color: #000;
    height: 1.6px;
}

.bottom-hr {
    margin: 0px 15px 0px 0px;
}

.top-hr {
    margin: 0px 0px 0px 15px;
}

.hr-line {
    border-top: 1px solid #D4D4D4;
    margin: 10px;
}

.edit-font {
    font-size: 13px;
    font-weight: 600;
}

.edit-bay {
    cursor: pointer;
    margin: 10px;
}

/* assign start */
.assign-box {
    height: 200px;
    margin: 10px;
}

.assign-bay-head {
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.assign-align {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* min-height: 170px; */
}

.drop-product {
    height: 100%;
    justify-content: center;
}

.drop-product-left {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap-reverse;
}

.planogram-assigned-product {
    /* position: relative; */
    /* border: 1px solid #D4D4D4; */
    zoom: 85%;
}

.planogram-assigned-close {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    background: #000000;
    padding: 2px 3px 2px 5px;
}

/* progress::-moz-progress-bar { background: var(--primary); }
progress::-webkit-progress-value { background: var(--primary); }
progress { color: var(--primary); } */

.planogram-progress-percent {
    position: absolute;
    bottom: -13px;
    left: -6px;
    font-size: 10px;
    font-weight: 800;
}

progress::-moz-progress-bar {
    background: var(--primary);
    height: 5px;
}


progress::-webkit-progress-value {
    background: var(--deal);
    height: 5px;
}

progress {
    background: var(--deal);
    height: 5px;
    vertical-align: super;
}

.w-25 {
    width: 25%;
}

.w-50 {
    width: 50%;
}

.assign-bay-section {
    display: flex;
}

.py-30 {
    padding-top: 30px;
    padding-bottom: 10px;
}

.assign-text {
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
}

.assign-plus-img {
    width: 85%;
    margin-left: 4px;
}

.box-shadow-bay {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* assign end */
/* configur-start */

.configure-section {
    padding-right: 30px;
    position: relative;
}

.planogram-outer-box {
    position: relative;
    padding-left: 30px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.shelve-name {
    position: absolute;
    left: 0;
    width: 30px;
    background-color: rgba(0, 0, 0, .03);
    height: 100%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shelve-name-text {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.config-content {
    width: 100%;
    /* height: 250px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    /* margin-left: 10px; */
}

.config-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.f-30 {
    font-size: 30px;
}

.gear-img {
    width: 3%;
}

.config-text {
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px;
}

.add-bay-section {
    display: flex;
    justify-content: end;
}

.add-bay-img {
    width: 3%;
}

.add-bay-text {
    font-size: 12px;
    font-weight: 600;
    margin: 15px 15px 0px 5px;
    cursor: pointer;
}

.arrow-height {
    background-color: #000;
    height: 100%;
    width: 1.25px;
    margin: 0 auto;
}

.card-img-assign-section {
    cursor: pointer;
    align-self: center;
    margin-top: 40px;
}

.arrow-text {
    align-items: center;
    display: flex;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
}

.arrow-rotate {
    writing-mode: vertical-lr;
}

.arrow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0px;
    right: 15px;
}

.planogram .card {
    min-width: 180px;
    min-height: 120px;
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal-container {
    min-height: 200px;
}

.p-relative {
    position: relative;
}

.planogram-unassign-btn {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}

.pb-2r {
    padding-bottom: 2rem;
}

.planogram-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ml-2 {
    margin-left: 1rem;
}

.planogram-wrap {
    box-sizing: border-box;
    /* max-width: 1175px; */
    padding: 0px 50px;
}

.planogram-search select {
    padding: 5px;
    font-size: 14px;
}

#planogram-search-height {
    max-height: 30vh;
    overflow: auto;
}

.planogram-search-product-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.planogram-search-card-content {
    margin-left: 5px;
    max-width: 98px;
}

.planogram-search-card {
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px;
    min-width: 100px;
    height: auto;
    overflow: auto;
}

.planogram-search-card p {
    font-size: 10px;
    display: inline-block;
    width: 75px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.planogram-search-img {
    width: 100%;
    object-fit: cover;
    margin: 0 auto;
    margin-bottom: 10px;
    height: 50px;
    object-fit: contain;
}

.planogram-search-card:hover {
    background-color: var(--deal);
    color: #fff;
}

.alert-danger p {
    color: red;
    font-size: medium;
    font-weight: 600;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.draggable-container {
    position: relative;
}

.planogram-card-container .draggable-container {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap-reverse;
}

.draggable-item {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 0px 0px 5px;
    z-index: 2;
}

.draggable-hidden {
    visibility: hidden;
}

.shelves-container {
    max-height: 80vh;
    overflow: auto;
}

.rack-canvas {
    margin-top: 20px;
    width: 100%;
    height: 500px;
    position: relative;
}

.rack-canvas .btn-maximize {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    padding: 0px 5px;
    cursor: pointer;
    background: #808080;
}


#planogram-3d-view {
    height: 45vh;
    overflow: auto;
}

#planogram-store-3d-view {
    height: 45vh;
    overflow: auto;
}

#planogram-3d-view-large {
    height: 90vh;
    overflow: auto;
}

.planogram-modal .modal-body {
    padding: 10px !important;
}

.draggable-item {
    background-color: #000000;
    padding: 2px 5px 0px 5px;
    color: #FFFFFF;
}

.planogram-card-container .draggable-container {
    border: 1px solid #D4D4D4;
}


.planogram-card-container .draggable-container.active {
    border: 2px solid #000000;
    zoom: 98%
}

.btn-rack-color.btn {
    color: #FFFFFF;
    padding: 0 !important;
    font-size: 1rem !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    min-width: unset !important;
}

.btn-cooler-color.btn {
    color: #FFFFFF;
    padding: 0 !important;
    font-size: 1rem !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    min-width: unset !important;
}

/* configure end */
/* Planogram Ended */

/* configure end */
/* Planogram Ended */
.m-1 {
    margin: -1.75rem !important;
    padding-right: 20px;
}

.mr-2 {
    margin-right: 1rem !important;
}

.btn-planogram-rack {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rack-name {
    position: absolute;
}

.dropdown-menu li {
    position: relative;
    cursor: pointer;
}

.dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 70%;
    top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
    display: block;
}

.dropdownList {
    background-color: white !important;
    color: black !important;
}

.bg-dgreen {
    background-color: #7bb657;
    color: white;
}

.bg-dblue {
    background-color: #5a9ddb;
    color: white;
}

.bg-dagrey {
    background-color: #a5a5a5;
    color: white;
}

.bg-dgrey {
    background-color: #a5a5a5;
    color: white;
    width: 80px;
    height: 50px;
}

.bg-dgrey:after {
    position: absolute;
    content: '';
    width: 35px;
    height: 35px;
    right: -16px;
    top: 7px;
    z-index: -1;
    background-color: #a5a5a5;
    transform: rotate(45deg);
}


.bg-dyellow {
    background-color: #ffc72c;
    color: white;
}

.bg-dorange {
    background-color: #f67b28;
    color: white;
}

.bg-dblack {
    background-color: #060606;
    color: white;
}

.btn-dgreen {
    background-color: #72b048;
    color: white;
}

.btn-orange {
    background-color: #ee8137;
    color: white;
}

.planogram-report {
    color: black;
    border: 1px solid;
    padding: 10px;
    box-shadow: 1px 3px #888888;
    background-color: white;
}

.rack-wise {
    display: flex;
    align-items: center
}

.plano_custom{
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.p-10 {
    padding: 10px;
}